<script setup lang="ts">
  import site from '~~/site'
  const { name } = site
</script>
<template>
  <NuxtLink data-pg-name="Logo" class="flex items-center sm:flex-row" to="/">
    <img  src="/images/logo/6.png" alt="Hibachi Now" class="lg:h-36 lg:mt-8 h-32 mt-4"  >
    <!-- <h6
      class="text-primary-600 dark:text-primary-200 font-extrabold font-serif ml-2 mt-4 text-2xl"
    >
      {{ name }}
    </h6> -->
  </NuxtLink>
</template>
<style scoped>

</style>
